import React from "react"
import { css } from "@emotion/react"
import {Link} from "gatsby"

export default function IntroTrialBlueprint(){
  return (
    <div css={IntroTrialBlueprintStyles}>
      <div className="intro">

        <div className="ctn">
          <div className="subtitle">
            <p>Topics Covered</p>
          </div>
          <p>
            Topics related to data collection and management including:
            <br/>
            Study Cycle | Datas & statistics | EDCs | Management | ICH & Norms
          </p>
        </div>

        <div className="ctn">
          <div className="subtitle">
            <p>What you'll gain from this consulting session</p>
          </div>
          <p>
            A clear picture of how to collect, manage and analyze reliable & compliant data.<br/>
            Unique and creative strategies adapted to the specificities of your trial.
          </p>
        </div>

        <div className="ctn">
          <div className="subtitle">
            <p>Who we are</p>
          </div>
          <p>
            Focus DataScience is a CRO that facilitates the decision-making process of health
            leaders thanks to meaningful data management, biostatistics and consulting. <a target="_blank" href="/about-us" >Learn More</a>
          </p>
        </div>

        <div className="subtitle">
          <p>Price</p>
          <p><s>$397</s> Free</p>
        </div>

      </div>
    </div>
  )
}


const IntroTrialBlueprintStyles = css`

  .subtitle {
    color: white;
    margin: 1rem auto;
    p{
      font-weight: 600 !important;
      s{
        text-decoration: line-through;
      }
    }
    max-width: 1000px;
  }
  div:last-of-type.subtile p{
    font-weight:lighter!important;
  }
  .intro {
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    position: center;
  }

  .ctn {
    width: 100%;
    margin: auto;
    max-width: 1000px;

    p {
      margin: 0 0 0.7rem 0;      
      a{
        color: rgba(250,250,250,0.5) !important;
      }
    } 
    p:last-of-type {
        margin: 0 0 2em 0
      }
  }

  .ctn:first-of-type {
    margin-bottom: 4rem;
  }

  p {
    text-align: start;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-size: 1.35rem;
  }

  h5 {
    font-size: 1.47rem;
    font-weight: 300;
  }
  

  @media (max-width: 700px) {
    .ctn  {
      width: 60%;
    }
  }

  @media (max-width: 600px) {
    .ctn {
      width: 70%;
      p{
        font-size: 1.25em;
      }
    }
    h2 {
      font-size: 2.15rem;
    }
  }
`