import React from "react"
import "../assets/styles/global.css"
import Footer from "../components/Bar/footer"
import NavBarWrapper from "../components/Bar/NavBarWrapper"
import BasicLayout from "../layouts/BasicLayout"
import QuoteTrialBlueprint from "../components/Content/QuoteTrialBlueprint"
import IntroTrialBlueprint from "../components/Content/IntroTrialBlueprint"
import { InlineWidget } from "react-calendly"
import Sponsors from "../components/Content/Testimonials/Sponsors"
import { SEO_OPTIONS_HANG_ON } from "../const/SEOOptions"


const DataManagementBlueprintPage = ({ location }) => {
  return (
    <BasicLayout
    SEOOptions={SEO_OPTIONS_HANG_ON}
    style={{background:"#01061A"}}

    >
      <NavBarWrapper location={location} h2={"Consulting Session"} h1={"Your Tailored Trial Blueprint"}/>
        <div style={{width:"80%", margin:"auto"}}>
          <QuoteTrialBlueprint />


          <IntroTrialBlueprint />

          <InlineWidget url={"https://calendly.com/nadiapetiot/tailored-data-plan"} styles={{height:"800px"}} />

          <Sponsors isAllRefDisplayed={false} h2={"Health Leaders that trust us"}/>

        </div>
      <Footer location={location} isFullpage={false}/>
    </BasicLayout>
  )
}

export default DataManagementBlueprintPage

