import React from "react"
import { css } from "@emotion/react"
import  { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function QuoteTrialBlueprint () {
  const data = useStaticQuery(graphql`
    {
      aboutImage: file(relativePath: { eq: "nadia.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return <div css={QuoteTrialBlueprintStyles}>

      <div className="quote">
        <Img
          fluid={data.aboutImage.childImageSharp.fluid}
          alt="Nadia Petiot"
        />
        <div className="ctn">
          <p>
            "A 15-years experience expert designs
            a tailored blueprint for your trial.
          </p>
          <p>
            You will then know how to collect,
            manage and analyze reliable data for your solution."
          </p>

          <div className="signature">
            <p>-<a href="https://www.linkedin.com/in/nadia-petiot-445ba515a/"  target="_blank">N.Petiot</a>, Head Of Data Management & Biostatistics</p>
          </div>
        </div>
        <b>
          "
        </b>

      </div>
    </div>

}


const QuoteTrialBlueprintStyles = css`
  
  b{
    font-size:250px;
    font-weight: 500;
    opacity:25%;
    transform:rotate(180deg);
    font-style:italic;
    position:relative;
    bottom:140px;
    left:40px;
  }
  .gatsby-image-wrapper{
    border-radius:50%;
    width:300px;
    height:250px;
    margin: 20px 50px;
  }
  

  .quote {
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    position: center;
    border:2px solid #3A3D4E;
    border-radius:15px;
    display:flex;
    max-width: 1000px;
    margin:auto;
    margin-bottom:4rem;
  }

  .ctn {
    width: 63%;
    margin: 2rem auto;

    p {
      margin: 0 0 1.2rem 0
    } 
    p:last-of-type {
        margin: 0 0 2em 0
      }
  }


  p {
    text-align: start;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight:lighter!important;
  }
  
  .signature{
    font-style:italic;
    color:rgba(255,255,255,0.4);
    a{
      color:inherit;
    }
  }
  

  @media (max-width: 700px) {
    .ctn  {
      width: 60%;
    }
    .quote{
      display:block!important;
    }
    .gatsby-image-wrapper{
      width: 36vw;
      height: 18vh;
      margin: 2rem auto;
    }
    b{    
      display:none;
    }
  }

  @media (max-width: 600px) {
    .ctn {
      width: 70%;
      p{
        font-size: 1.25em;
      }
    }
    h2 {
      font-size: 2.15rem;
    }
  }
`